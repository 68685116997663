<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-btn class="ma-1" large solo depressed color="secondary" :to="{ name: 'dashboard_usuarios_create' }">
                    <v-icon class="mr-2">person</v-icon>
                    <span class="subtitle-2">Crear acceso</span>
                </v-btn>
                <v-btn class="ma-1" large solo depressed color="secondary" @click="show_import_user">
                    <v-icon class="mr-2">face</v-icon>
                    <span class="subtitle-2">Integrar usuario GMA</span>
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <DashCard title="Listado de Usuarios" title-icon="list" body-class="pa-0">
                    <v-tabs fixed-tabs>
                        <v-tab class="subtitle-2 px-4 py-4">Todos</v-tab>
                        <v-tab class="subtitle-2 px-4 py-4">Evaluadores</v-tab>
                        <v-tab class="subtitle-2 px-4 py-4">Participantes</v-tab>
                        <v-tab-item>
                            <v-divider />
                            <UsuarioTable capacitaciones buscador :items="index" @change="$parent.$parent.reload_dashboard()" />
                        </v-tab-item>
                        <v-tab-item>
                            <v-divider />
                            <UsuarioTable capacitaciones buscador :items="cap_evaluadores" @change="$parent.$parent.reload_dashboard()" />
                        </v-tab-item>
                        <v-tab-item>
                            <v-divider />
                            <UsuarioTable capacitaciones buscador :items="cap_participantes" @change="$parent.$parent.reload_dashboard()" />
                        </v-tab-item>
                    </v-tabs>
                </DashCard>
            </v-col>
        </v-row>
        <DialogImportGMA ref="import_dialog" @success="$parent.$parent.reload_dashboard()" />
        <Message ref="dialog" />
    </div>
</template>

<script>
import DialogImportGMA from '@/educap/dialogs/DialogImportGMA'
import DashCard from '@/shared/components/DashCard'
import Message from '@/shared/components/Message'
import UsuarioTable from '@/shared/components/UsuarioTable'
import { axiosHelper, messages } from '@/helpers'

export default {
    components: {
        DashCard,
        Message,
        UsuarioTable,
        DialogImportGMA
    },
    data: () => ({
        index: []
    }),
    computed: {
        tabla_loading() {
            return !this.index;
        },
        cap_evaluadores() {
            return this.index.filter(x => x.groups.includes('cap_evaluador'));
        },
        cap_participantes() {
            return this.index.filter(x => x.groups.includes('cap_participante'));
        }
    },
    methods: {
        show_import_user() {
            this.$refs.import_dialog.show();
        }
    },
    mounted() {
        axiosHelper.get('edu/usuarios/')
            .then(response => {
                this.index = response.data;
            })
        this.$parent.$parent.set_title("Accesos a Capacitaciones", "edit");
    }
}
</script>