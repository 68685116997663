<template>
    <v-dialog v-model="visible" max-width="800px" transition="fade-transition">
        <WindowCard title="Importar datos de perfil de empleado" title-icon="style" :actions="true" actions-align="right">
            <v-form id="form" ref="form">
                <v-row>
                    <v-col cols="12">
                        <v-label>Seleccione un usuario de la plataforma general GMA para integrar su acceso a esta plataforma, conservando sus datos sin modificación alguna.</v-label>
                    </v-col>
                    <v-col cols="12">
                        <v-card outlined flat>
                        <PersonaListItem v-model="perfil" />
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-autocomplete
                            clearable solo outlined flat dense 
                            prepend-inner-icon="account_circle" label="Seleccione un perfil"
                            v-model="persona" :items="usuarios"
                            :item-text="(i) => `${i.perfil.first_name} ${i.perfil.last_name} (${i.username})`" 
                            return-object />
                    </v-col>
                    <v-col cols="12">
                        <v-label>Seleccione el tipo de usuario que podrá usar en esta plataforma.</v-label>
                        <v-radio-group v-model="grupo">
                            <v-radio hide-details value="cap_evaluador">
                                <template slot="label">
                                    <div>
                                        <p class="title pa-0 ma-0">Evaluador/a</p>
                                        <p class="subtitle pa-0 ma-0">Podrá responder evaluaciones grupales y administrar sus grupos de acceso asignados.</p>
                                    </div>
                                </template>
                            </v-radio>
                            <v-radio hide-details value="cap_participante">
                                <template slot="label">
                                    <div>
                                        <p class="title pa-0 ma-0">Participante</p>
                                        <p class="subtitle pa-0 ma-0">Solo podrá responder las evaluaciones de cada capacitación que se le asigne.</p>
                                    </div>
                                </template>
                            </v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
            </v-form>
            <template slot="actions">
                <v-btn depressed color="primary" class="subtitle-2" large @click="save">
                    <v-icon left>done</v-icon>
                    Aceptar
                </v-btn>
                <v-btn depressed color="primary" class="subtitle-2" large @click="cancel">
                    <v-icon left>close</v-icon>
                    Cancelar
                </v-btn>
            </template>
        </WindowCard>
        <Message ref="error" />
    </v-dialog>
</template>

<script>
import WindowCard from '@/shared/components/WindowCard'
import PersonaListItem from '@/dash/components/PersonaListItem'
import Message from '@/shared/components/Message'
import { axiosHelper, messages } from '@/helpers'

export default {
    props: ['value'],
    components: {
        WindowCard,
        PersonaListItem,
        Message
    },
    data: () => ({
        persona: null,
        grupo: null,
        usuarios: [],
        visible: false
    }),
    computed: {
        perfil() {
            if(!this.persona) return null;
            return this.persona.perfil;
        }
    },
    methods: {
        show() {
            this.persona = null;
            this.grupo = null;
            this.visible = true;
            axiosHelper.get('usuarios/').then(response => this.usuarios = response.data);
        },
        save() {

            if(!this.$refs.form.validate()) {
                this.$refs.error.show(messages.titles["error_generico"], messages.texts["error_check_fields"], "error");
                return;
            }

            if (!this.persona) {
                this.$refs.error.show(messages.titles["error_generico"], 'Debe seleccionar un usuario', "error");
                return;
            }

            axiosHelper.post('edu/usuarios/importar/', { id: this.persona.id, grupo: this.grupo })
                .then(response => {
                    this.$emit("success");
                    this.visible = false;
                })
                .catch(response => this.$refs.error.show(messages.titles['error_generico'], messages.texts['error_desconocido'], "error"));
        },
        cancel() {
            this.visible = false;
        }
    }
}
</script>